import React, {useState} from 'react';
import {Button, component, Dropdown, layout, Row} from 'front-components';
import {IconMenu24, IconArrowNW24} from 'front-components/src/icons';

import {NavHeaderQaAttrs} from 'interfaces/models';
import {DataAnalytics} from 'stores/Analytics/dataAnalitycs';
import {RowDropdownOption} from './RowDropdownOption';
import {CreateEventButton} from './CreateEventButton';
import {externalLinks} from '../const';
import cx from 'classnames';

interface IBurgerMenuProps {
    onAuthClick: () => void;
}
export const BurgerMenu: React.FC<IBurgerMenuProps> = ({onAuthClick}) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleLoginClick = () => {
        setIsOpen(false);
        onAuthClick();
    };

    return (
        <Dropdown
            fixPositionOnScroll
            customMobileBreakpoint={767}
            trigger={() => (
                <Button
                    className={component('auth-button', 'burger-menu')()}
                    icon={<IconMenu24 />}
                    variant={Button.variant.transparent}
                    labelColor="#252525"
                    onClick={() => setIsOpen((_isOpen) => !_isOpen)}
                    data-qa={NavHeaderQaAttrs.BurgerMenu}
                />
            )}
            on={[]}
            show={isOpen}
            onClose={() => setIsOpen(false)}
            priorityPositions="bottom-left"
        >
            <div className={cx(component('theme--darkpic-bg')(), component('theme--darkpic')())}>
                {externalLinks.map((el) => (
                    <RowDropdownOption key={el.label} icon={<IconArrowNW24 />} {...el} />
                ))}
                <Row horizontalPadding={8}>
                    <Row.Body style={{paddingBottom: 0}}>
                        <Button
                            className={layout('flex--justify-centered')()}
                            data-analytics={DataAnalytics.enter}
                            onClick={handleLoginClick}
                        >
                            Войти
                        </Button>
                    </Row.Body>
                </Row>

                <CreateEventButton />
            </div>
        </Dropdown>
    );
};
