import React, {FC, useState} from 'react';
import phoneFormatter from 'phone-formatter';
import {observer} from 'mobx-react';
import {Button, component, Divider, Dropdown, Pic, Row, Typography} from 'front-components';
import {useContainer} from 'timepad-di';
import {IconEdit24, IconArrowNW24} from 'front-components/src/icons';

import {DataAnalytics} from 'stores/Analytics/dataAnalitycs';
import {AuthStore} from 'stores/Auth';
import {UserStore} from 'stores/User';
import {WindowProfileQaAttrs, NavHeaderQaAttrs} from 'interfaces/models';
import {useStores} from 'services/hooks';
import {IRowDropdownOption} from '../types';
import {externalLinks, profileList} from '../const';
import {CreateEventButton} from './CreateEventButton';
import {RowDropdownOption} from './RowDropdownOption';
import cx from 'classnames';

interface IProfileButtonProps {
    mediaPoint: number;
    isEventPage: boolean;
}

export const ProfileButton: FC<IProfileButtonProps> = observer(({mediaPoint, isEventPage}) => {
    const [userStorage] = useContainer(UserStore);
    const [authStorage] = useContainer(AuthStore);
    const {modalStorage} = useStores();

    const phone = userStorage.user ? phoneFormatter.format(userStorage.user.phone, '+7 (NNN) NNN NN NN') : null;

    const [isOpen, setIsOpen] = useState(false);

    const handleEditUserClick = () => {
        modalStorage.modals.PROFILE_MODAL.open({eventPage: isEventPage});
        setIsOpen(false);
    };

    const handleDropdownOptionsClick = (element: IRowDropdownOption) => () => {
        element?.onClick?.();
        setIsOpen(false);
    };

    return (
        <Dropdown
            customMobileBreakpoint={767}
            fixPositionOnScroll
            show={isOpen}
            onClose={() => setIsOpen(false)}
            offsetY={!(mediaPoint < 2) && isEventPage ? 16 : 0}
            on={[]}
            trigger={() => (
                <Pic
                    onClick={() => setIsOpen((_isOpen) => !_isOpen)}
                    data-analytics={DataAnalytics.userProfile}
                    imgURL={userStorage?.user?.avatarUrl}
                    label={userStorage?.user?.name?.[0]}
                    hoverable
                    data-qa={NavHeaderQaAttrs.BtnProfile}
                    className={component('profile-button', 'user-pic')()}
                />
            )}
            data-qa={WindowProfileQaAttrs.WindowProfile}
        >
            <Dropdown.Header down center element>
                <Row hoverable onClick={handleEditUserClick} data-qa={WindowProfileQaAttrs.BtnEditProfile}>
                    <Row.Icon className="userpic--mobile">
                        <Pic imgURL={userStorage?.user?.avatarUrl} label={userStorage?.user?.name?.[0]} hoverable />
                    </Row.Icon>
                    <Row.Body style={{padding: '9px 0'}}>
                        <Row.Text>
                            <Typography variant="caption" fontWeight="bold" noPadding>
                                {userStorage?.user?.name ? userStorage.user.name : phone}
                            </Typography>
                        </Row.Text>
                        {userStorage.user && userStorage.user.name && (
                            <Row.Caption>
                                <Typography variant="small" noPadding>
                                    {phone}
                                </Typography>
                            </Row.Caption>
                        )}
                    </Row.Body>
                    <Row.Icon>
                        <Button icon={<IconEdit24 />} variant={Button.variant.transparent} />
                    </Row.Icon>
                </Row>
            </Dropdown.Header>
            <div className={cx(component('theme--darkpic-bg')(), component('theme--darkpic')())}>
                {profileList(authStorage.logout).map((el) => (
                    <RowDropdownOption {...el} key={el.label} onClick={handleDropdownOptionsClick(el)} />
                ))}
                {mediaPoint === 0 && (
                    <>
                        <Divider />
                        {externalLinks.map((el) => (
                            <RowDropdownOption
                                {...el}
                                key={el.label}
                                icon={<IconArrowNW24 />}
                                onClick={handleDropdownOptionsClick(el)}
                            />
                        ))}
                        <CreateEventButton />
                    </>
                )}
            </div>
        </Dropdown>
    );
});
